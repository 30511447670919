import React from 'react';
import { colors } from '../../config';

export const TimetableWithHeadings = (props) => {
    
    const { timeTableArray } = props;

    return (
        <div>
            <table>
            <tr>
                <td>THEORY
                <br />HOURS</td>
                <td>08:00 AM
                    <br />to
                    <br />08:50 AM</td>
                <td>09:00 AM
                    <br />to
                    <br />09:50 AM</td>
                <td>10:00 AM
                    <br />to
                    <br />10:50 AM</td>
                <td>11:00 AM
                    <br />to
                    <br />11:50 AM</td>
                <td>12:00 PM
                    <br />to
                    <br />12:50 PM</td>
                <td>-</td>
                <td>LUNCH</td>
                <td>02:00 PM
                    <br />to
                    <br />02:50 PM</td>
                <td>03:00 PM
                    <br />to
                    <br />03:50 PM</td>
                <td>04:00 PM
                    <br />to
                    <br />04:50 PM</td>
                <td>05:00 PM
                    <br />to
                    <br />05:50 PM</td>
                <td>06:00 PM
                    <br />to
                    <br />06:50 PM</td>
                <td>06:50 PM
                    <br />to
                    <br />07:00 PM</td>
                <td>07:00 PM
                    <br />to
                    <br />07:50 PM</td>
            </tr>

            <tr>
                <td>LAB
                    <br />HOURS</td>
                <td>08:00 AM
                    <br />to
                    <br />08:45 AM</td>
                <td>08:46 AM
                    <br />to
                    <br />09:30 AM</td>
                <td>10:00 AM
                    <br />to
                    <br />10:45 AM</td>
                <td>10:46 AM
                    <br />to
                    <br />11:30 AM</td>
                <td>11:31 AM
                    <br />to
                    <br />12:15 AM</td>
                <td>12:16 AM
                    <br />to
                    <br />01:00 PM</td>
                    <td>LUNCH</td>
                <td>02:00 PM
                    <br />to
                    <br />02:45 PM</td>
                <td>02:46 PM
                    <br />to
                    <br />03:30 PM</td>
                <td>04:00 PM
                    <br />to
                    <br />04:45 PM</td>
                <td>04:46 PM
                    <br />to
                    <br />05:30 PM</td>
                <td>05:31 PM
                    <br />to
                    <br />06:15 PM</td>
                <td>06:16 PM
                    <br />to
                    <br />07:00 PM</td>
                <td>-</td>
            </tr>

                <tbody>
                {
                    timeTableArray.map((day, dayIndex) => {
                        return (
                            <tr>
                                <td>{ ['MON','TUE','WED', 'THU', 'FRI', 'SAT', 'SUN'][dayIndex] }</td>
                                {
                                    day.map((period) => (
                                        <td bgcolor={period.length ? colors.timetableCellFull: colors.timetableCellEmpty} style={{border: `0.5px solid ${colors.timetableCellBorder}`, padding: 5, width: 100}}>{period || '-'}</td>
                                    ))
                                }
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
    )
}