/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react';
import { 
    Layout,
    Menu,
    Icon,
} from 'antd';
import { headerRoutes } from '../../config';

import './index.css';

const { Header, Content, Footer } = Layout;

export class LayoutWrapper extends Component {

    renderMenuItems = () => {
        return(
            headerRoutes.map((route) => {
                return(
                    <Menu.Item key={route.path}>
                        <a href={route.path}>
                            <Icon type={route.icon} />
                            <span className="nav-text">{route.name}</span>
                        </a>
                    </Menu.Item>
                    )
            })
        )
    }

    render() {
        return (
            <Layout className="layout">
                <Header>
                    <div className="logo" />
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        style={{ lineHeight: '64px' }}
                    >
                        {this.renderMenuItems()}
                    </Menu>
                </Header>
                <Content style={{ padding: '0 50px' }}>
                    <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>{this.props.children}</div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Made with ❤️ by <a href='https://mounishsai.com' target='_blank'>Mounish Sai</a> <br />
                    <div style={{ margin: '10px 0' }} />
                    <a href='https://www.facebook.com/pvsmounish' target='_blank'><Icon type="facebook" style={{ fontSize: '25px', marginRight: '5px' }} /></a>
                    <a href='https://www.instagram.com/pvsmounish/' target='_blank'><Icon type="instagram" style={{ fontSize: '25px', marginRight: '5px' }} /></a>
                    <a href='https://www.linkedin.com/in/pvsmounish/' target='_blank'><Icon type="linkedin" style={{ fontSize: '25px', marginRight: '5px' }} /></a>
                    <a href='https://www.github.com/pvsmounish/' target='_blank'><Icon type="github" style={{ fontSize: '25px', marginRight: '5px' }} /></a>
                </Footer>
            </Layout>
        )
    }

}