import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    Input,
    Spin,
    Button,
} from 'antd';
import { Query } from '@apollo/react-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { VIEW_MERGE_TIMETABLE } from '../../../api/queries';
import { MergeTable } from './mergeTable';
import { MergeTimetableDetails } from './details';
import { MergeTimetableUserList } from './userList';
import { MergeTimetableAddUsers } from './addUsers';

export class ViewMergeTimetable extends Component {

    state = {
        currentCell: [-1, -1],
    }

    setCurrentCell = async (day, period) => {
        await this.setState({ currentCell: [ day, period ] });
    }
    
    render() {

        const { mergeTimetableId } = this.props.match.params;
        const { currentCell } = this.state;

        return (
            <div>
                <Query query={VIEW_MERGE_TIMETABLE} variables={{ id: mergeTimetableId }}>
                    {({ data, loading, error }) => {
                        if (loading) return <div className="spin-container"><Spin size='large' /></div>;
                        // if (error) return <p>Error Fetching Data</p>;

                        if(error || !data.mergeTimetable){
                            return (
                                <div>
                                    <p>Not Found</p>
                                </div>
                            )
                        }
                        
                        const { name, id, timetables } = data.mergeTimetable;

                        return (
                            <div>
                                <Row>
                                    <Col span={12} offset={6}>
                                        <Card>
                                            <h2>{name}'s Merged Timetable</h2>
                                            <p>Don't Share Your Merged Timetable ID with Anyone. Anyone who have that ID can make changes in it.</p>
                                            <Input
                                                addonBefore="Merge Timetable ID"
                                                size="large"
                                                defaultValue={id}
                                                readOnly
                                                suffix={
                                                    <CopyToClipboard text={id}>
                                                        <Button icon="copy" />
                                                    </CopyToClipboard>
                                                }
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                                <MergeTable data={{ timetables, currentCell }} setCurrentCell={this.setCurrentCell} />
                                <div style={{ margin: '24px 0' }} />
                                <MergeTimetableDetails data={{ timetables, currentCell }} />
                                <div style={{ margin: '24px 0' }} />
                                <MergeTimetableUserList data={{ timetables, mergeTimetableId }} />
                                <div style={{ margin: '24px 0' }} />
                                <MergeTimetableAddUsers data={{ mergeTimetableId }} />
                            </div>
                        );
                    }}
                </Query>
            </div>
        );
    }
}
