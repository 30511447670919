import React from 'react';
import './index.css';

import { getOrganisationLogos } from '../../utils';

export const OrganisationsLogos = () => {

    const organisationLogos = getOrganisationLogos({ shuffle: true });

    return (
        <>
            <div className="organisation-logos">
                {
                    organisationLogos.map(({ name, fileName }) => (
                        <div>
                            <img src={`/images/organisations/${fileName}`} className="organisation-logo" alt={name} title={name} />
                        </div>
                    ))
                }
            </div>
        </>
    )
}