import * as Pages from '../pages'

export const routes = [
    {
        name: 'Home',
        icon: 'home',
        path: '/',
        page: Pages.Home,
    },
    {
        name: 'New Timetable',
        icon: 'plus',
        path: '/timetable/new',
        page: Pages.NewTimetable,
    },
    {
        name: 'View Timetable',
        icon: 'table',
        path: '/timetable/:timetableId',
        page: Pages.ViewTimetable,
    },
    {
        name: 'New Merge Timetable',
        icon: 'plus-circle',
        path: '/merge-timetable/new',
        page: Pages.NewMergeTimetable,
    },
    {
        name: 'View Merge Timetable',
        icon: 'bar-chart',
        path: '/merge-timetable/:mergeTimetableId',
        page: Pages.ViewMergeTimetable,
    },
]

export const headerRoutes = [
    {
        name: 'Home',
        icon: 'home',
        path: '/',
        page: Pages.Home,
    },
    {
        name: 'New Timetable',
        icon: 'plus',
        path: '/timetable/new',
        page: Pages.NewTimetable,
    },
    {
        name: 'New Merge Timetable',
        icon: 'plus-circle',
        path: '/merge-timetable/new',
        page: Pages.NewMergeTimetable,
    },
]