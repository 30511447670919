import React, { Component } from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { VITSPOT_TIMETABLE_API_URI } from '../config';

const client = new ApolloClient({
    uri: VITSPOT_TIMETABLE_API_URI,
});

export class GraphQLClient extends Component {
    render() {
        return (
            <ApolloProvider client={client}>
                { this.props.children }
            </ApolloProvider>
        );
    }
}