import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
} from 'antd';

export class MergeTimetableDetails extends Component {

    render() {

        const { timetables: users, currentCell } = this.props.data;
        const [ dayIndex, periodIndex ] = currentCell;

        return (
            <>
                <Row>
                    <Col span={14} offset={5}>
                        <Card title="Details">
                            <Row gutter={10}>
                                <Col span={12}>
                                    <Card type="inner" title="Free">
                                        <b>Students who don't have any classes at that slot.</b>
                                            {
                                                dayIndex !== -1 && periodIndex !== -1 && 
                                                <Card style={{ marginTop: '10px' }}>
                                                    {
                                                        users.filter((user) => {
                                                            return !user.timetable[dayIndex][periodIndex];
                                                        })
                                                        .map(({ name, registrationNumber }) => (
                                                            <p>{ `${name} (${registrationNumber})` }</p>
                                                        ))
                                                    }
                                                </Card>
                                            }
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card type="inner" title="Have Classes">
                                        <b>Students who have classes at that slot.</b>
                                            {
                                                dayIndex !== -1 && periodIndex !== -1 && 
                                                <Card style={{ marginTop: '10px' }}>
                                                    {
                                                        users.filter((user) => {
                                                            return !!user.timetable[dayIndex][periodIndex]; // !! -> gives the truthy/falsy value of given object
                                                        })
                                                        .map(({ name, registrationNumber }) => (
                                                            <p>{ `${name} (${registrationNumber})` }</p>
                                                        ))
                                                    }
                                                </Card>
                                            }
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}