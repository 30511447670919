export const getCellColor = (ratio) => {
    const percentage = (ratio * 100)%101;
    let r, g, b = 0; // Red, Green, Blue
    if(percentage < 50){
        r = 255;
        g = Math.round(5.1 * percentage);
    }
    else if(percentage >= 50){
        g = 255;
        r = Math.round(510 - 5.10 * percentage);
    }

    const h = r * 0x10000 + g * 0x100 + b * 0x1;

    return '#' + ('000000' + h.toString(16)).slice(-6);
}