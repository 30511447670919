import React, { Component } from 'react';

import { getCellColor } from '../../../../utils';

import './index.css';

export class MergeTable extends Component {

    render() {

        const { timetables: users } = this.props.data;
        const { setCurrentCell } = this.props;
        const usersLength = users.length;
        const days = ['Mon','Tue','Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const periodIndexes = [...Array(14).keys()];

        return (
            <div>
                <div className="container-fluid text-center noselect mt-3">
                    <table id="timetable" border="1" className="table table-bordered">
                        <thead>
                            <tr>
                                <th>THEORY
                                    <br />HOURS</th>
                                <th>08:00 AM
                                    <br />to
                                    <br />08:50 AM</th>
                                <th>09:00 AM
                                    <br />to
                                    <br />09:50 AM</th>
                                <th>10:00 AM
                                    <br />to
                                    <br />10:50 AM</th>
                                <th>11:00 AM
                                    <br />to
                                    <br />11:50 AM</th>
                                <th>12:00 PM
                                    <br />to
                                    <br />12:50 PM</th>
                                <th>-</th>
                                <th>LUNCH</th>
                                <th>02:00 PM
                                    <br />to
                                    <br />02:50 PM</th>
                                <th>03:00 PM
                                    <br />to
                                    <br />03:50 PM</th>
                                <th>04:00 PM
                                    <br />to
                                    <br />04:50 PM</th>
                                <th>05:00 PM
                                    <br />to
                                    <br />05:50 PM</th>
                                <th>06:00 PM
                                    <br />to
                                    <br />06:50 PM</th>
                                <th>06:50 PM
                                    <br />to
                                    <br />07:00 PM</th>
                                <th>07:00 PM
                                    <br />to
                                    <br />07:50 PM</th>
                            </tr>
                            <tr>
                                <th>LAB
                                    <br />HOURS</th>
                                <th>08:00 AM
                                    <br />to
                                    <br />08:45 AM</th>
                                <th>08:46 AM
                                    <br />to
                                    <br />09:30 AM</th>
                                <th>10:00 AM
                                    <br />to
                                    <br />10:45 AM</th>
                                <th>10:46 AM
                                    <br />to
                                    <br />11:30 AM</th>
                                <th>11:31 AM
                                    <br />to
                                    <br />12:15 AM</th>
                                <th>12:16 AM
                                    <br />to
                                    <br />01:00 PM</th>
                                <th>LUNCH</th>
                                <th>02:00 PM
                                    <br />to
                                    <br />02:45 PM</th>
                                <th>02:46 PM
                                    <br />to
                                    <br />03:30 PM</th>
                                <th>04:00 PM
                                    <br />to
                                    <br />04:45 PM</th>
                                <th>04:46 PM
                                    <br />to
                                    <br />05:30 PM</th>
                                <th>05:31 PM
                                    <br />to
                                    <br />06:15 PM</th>
                                <th>06:16 PM
                                    <br />to
                                    <br />07:00 PM</th>
                                <th>-</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                days.map((day, dayIndex) => (
                                        <React.Fragment key={dayIndex}>
                                            <tr>
                                                <td>{ day.toUpperCase() }</td>
                                                {
                                                    periodIndexes.map((periodIndex) => {
                                                        let noClassCount = 0;
                                                        for(let user of users){
                                                            const { timetable: userTimetable } = user;
                                                            if(!userTimetable[dayIndex][periodIndex]) noClassCount++;
                                                        }

                                                        const backgroundColor = getCellColor(noClassCount/usersLength);

                                                        return (
                                                            <React.Fragment key={periodIndex}>
                                                                <td
                                                                    className="periodCell"
                                                                    style={{ backgroundColor }}
                                                                >
                                                                    <button
                                                                        style={{ backgroundColor, border: 'none', cursor: 'pointer' }}
                                                                        onClick={ () => setCurrentCell(dayIndex, periodIndex) }
                                                                    >
                                                                        { `${noClassCount}/${usersLength}` }
                                                                    </button>
                                                                </td>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </React.Fragment>
                                    
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}