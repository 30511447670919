import gql from 'graphql-tag';

export const CREATE_TIMETABLE = gql`
    mutation createTimetable($name: String!, $registrationNumber: String!, $timetable: [[String!]!]!){
        createTimetable(name: $name, registrationNumber: $registrationNumber, timetable: $timetable){
            name,
            id,
        }
    }
`;

export const VIEW_TIMETABLE = gql`
    query timetable($id: ID!){
        timetable(id: $id){
            id,
            name,
            registrationNumber,
            timetable,
        }
    }
`;