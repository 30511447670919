import React, { Component } from 'react';
import { 
    Card,
    Spin,
    Row,
    Col,
    Input,
    Button,
} from 'antd';
import { Query } from '@apollo/react-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';

import { VIEW_TIMETABLE } from '../../../api/queries';
import { TimetableWithHeadings } from '../../../components';

import './index.css';

export class ViewTimetable extends Component {

    state = {
        timeTableArray: [],
        name: '',
        isInRecord: true,
        studentName: '',
        studentRegistrationNumber: '',
        studentEmail: '',
    }

    render() {

        const { timetableId } = this.props.match.params;

        return (
            <div>
                <Query query={VIEW_TIMETABLE} variables={{ id: timetableId }}>
                    {({ data, loading, error }) => {
                        if (loading) return <div className="spin-container"><Spin size='large' /></div>;
                        // if (error) return <p>Error Fetching Data</p>;

                        if(error || !data.timetable){
                            return (
                                <div>
                                    <p>Not Found</p>
                                </div>
                            )
                        }
                        const { name, registrationNumber, timetable, id } = data.timetable;

                        return (
                            <div>
                                <h2>{name}'s Timetable</h2>
                                <div style={{ margin: '24px 0' }} />
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col span={24} sm={12}>
                                        <Card>
                                            <p>Name: <b>{name}</b></p>
                                            <p>Registration Number: <b>{registrationNumber}</b></p>
                                            <p>Sharing Timetable ID is same as sharing your Timetable</p>
                                            <Input
                                                addonBefore="Timetable ID"
                                                size="large"
                                                defaultValue={id}
                                                readOnly
                                                suffix={
                                                    <CopyToClipboard text={id}>
                                                        <Button icon="copy" />
                                                    </CopyToClipboard>
                                                }
                                            />
                                            {/* <p>{email}</p> */}
                                        </Card>
                                    </Col>
                                    <Col span={24} sm={12}>
                                        <Card>
                                            <QRCode value={id} />
                                        </Card>
                                    </Col>
                                </Row>
                                <div style={{ margin: '24px 0' }} />
                                <TimetableWithHeadings  timeTableArray={timetable} />
                                <div style={{ margin: '24px 0' }} />
                            </div>
                        );
                    }}
                </Query>
                <div style={{ margin: '24px 0' }} />
            </div>
        );
    }
}
