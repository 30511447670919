import React, { Component } from 'react';
import { 
    Card,
    Input,
    Button,
    Spin,
    Modal,
} from 'antd';
import { Mutation } from '@apollo/react-components';

import { CREATE_MERGE_TIMETABLE } from '../../../api/queries';
const { TextArea } = Input;

export class NewMergeTimetable extends Component {

    state = {
        timetableIds: "",
        name: "",
        mergeButtonIsDisable: false,
        loading: false,
    }

    showErrorModal = () => {
        Modal.error({
            title: 'Error!',
            content: 'Oops something went wrong :(',
        });
    }

    showInfoModal = ({ title, content }) => {
        Modal.info({
            title: title || 'Information!',
            content,
        });
    }
    
    render() {

        const { timetableIds, name } = this.state;

        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <p>Paste Timetable IDs each in one line</p>
                    <TextArea autosize={{ minRows: 20, maxRows: 25 }} onChange={(e) => this.setState({timetableIds: e.target.value})} value={timetableIds}/>
                    <div style={{ margin: '24px 0' }} />
                    <Card>
                        <p>Club/Group Name</p>
                        <Input placeholder="Club Name" onChange={(e) => this.setState({name: e.target.value})} value={name} />
                        <div style={{ margin: '24px 0' }} />
                        {/* <Input placeholder="Enter Captcha" />
                        <div style={{ margin: '24px 0' }} /> */}
                        <Mutation mutation={CREATE_MERGE_TIMETABLE}>
                            {(createMergeTimetable, { data }) => (
                                <Button type="primary" block disabled={this.state.mergeButtonIsDisable} onClick={async () => {

                                    let timetableIdsArray = timetableIds.trim().split(/\s+/);
                                    // Original - Trim - Remove empty values
                                    timetableIdsArray.map(timetableId => timetableId.trim()).filter(timetableId => (timetableId));
                                    timetableIdsArray = Array.from(new Set(timetableIdsArray));
        
                                    if(name && timetableIdsArray.length > 0){
                                        await this.setState({ loading: true });
                                        try {
                                            const response = await createMergeTimetable({
                                                variables: {
                                                    name,
                                                    timetableIds: timetableIdsArray,
                                                }
                                            });
                                            window.location = `/merge-timetable/${response.data.createMergeTimetable.id}`;
                                        } catch (error) {
                                            this.showErrorModal();
                                        }
                                        await this.setState({ loading: false });
                                    }
                                    else {
                                        this.showInfoModal({ content: 'All fields are mandatory!' });
                                    }
                                }}>Merge</Button>
                            )}
                        </Mutation>
                    </Card>
                </Spin>
            </div>
        );
    }
}
