import gql from 'graphql-tag';

export const CREATE_MERGE_TIMETABLE = gql`
    mutation createMergeTimetable($name: String!, $timetableIds: [String!]!){
        createMergeTimetable(name: $name, timetableIds: $timetableIds){
            id,
            name,
        }
    }
`;

export const VIEW_MERGE_TIMETABLE = gql`
    query mergeTimetable($id: ID!){
        mergeTimetable(id: $id){
            id,
            name,
            timetables {
                id,
                name,
                registrationNumber,
                timetable,
            }
        }
    }
`;

export const DELETE_TIMETABLE_FROM_MERGE_TIMETABLE = gql`
    mutation deleteTimetableFromMergeTimetable($mergeTimetableId: ID!, $timetableId: String!){
        deleteTimetableFromMergeTimetable(mergeTimetableId: $mergeTimetableId, timetableId: $timetableId){
            id
        }
    }
`;

export const ADD_TIMETABLES_TO_MERGE_TIMETABLE = gql`
    mutation addTimetablesToMergeTimetable($mergeTimetableId: ID!, $timetableIds: [String!]!){
        addTimetablesToMergeTimetable(mergeTimetableId: $mergeTimetableId, timetableIds: $timetableIds){
            id
        }
    }
`;
