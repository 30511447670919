import React, { Component } from 'react';
import {
    Button,
    Col,
    Card,
    Input,
    Modal,
    Row,
    Spin,
} from 'antd';
import { Mutation } from '@apollo/react-components';

import { ADD_TIMETABLES_TO_MERGE_TIMETABLE } from '../../../../api/queries';

const { TextArea } = Input;

export class MergeTimetableAddUsers extends Component {

    state = {
        loading: false,
        timetableIds: "",
    }

    showErrorModal = () => {
        Modal.error({
            title: 'Error!',
            content: 'Oops something went wrong :(',
        });
    }

    showInfoModal = ({ title, content }) => {
        Modal.info({
            title: title || 'Information!',
            content,
        });
    }

    render() {

        const { timetableIds } = this.state;
        const { mergeTimetableId } = this.props.data;

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col span={14} offset={5}>
                            <Card title="Add Users">
                                <p>Paste Timetable IDs each in one line</p>
                                <TextArea autosize={{ minRows: 5, maxRows: 10 }} placeholder="Timetable IDs" onChange={(e) => this.setState({timetableIds: e.target.value})} value={timetableIds} />
                                <div style={{ margin: '24px 0' }} />
                                <Mutation mutation={ADD_TIMETABLES_TO_MERGE_TIMETABLE}>
                                    {(addTimetablesToMergeTimetable, { data }) => (
                                        <Button type="primary" block disabled={this.state.mergeButtonIsDisable} onClick={async () => {

                                            let timetableIdsArray = timetableIds.trim().split(/\s+/);
                                            // Original - Trim - Remove empty values
                                            timetableIdsArray.map(timetableId => timetableId.trim()).filter(timetableId => (timetableId));
                                            // Remove Duplicates
                                            timetableIdsArray = Array.from(new Set(timetableIdsArray));
                
                                            if(timetableIds && timetableIdsArray.length > 0){
                                                await this.setState({ loading: true });
                                                try {
                                                    await addTimetablesToMergeTimetable({
                                                        variables: {
                                                            mergeTimetableId,
                                                            timetableIds: timetableIdsArray,
                                                        }
                                                    });
                                                    window.location.reload();
                                                } catch (error) {
                                                    this.showErrorModal();
                                                }
                                                await this.setState({ loading: false });
                                            }
                                            else {
                                                this.showInfoModal({ content: 'All fields are mandatory!' });
                                            }
                                        }}>Add</Button>
                                    )}
                                </Mutation>
                            </Card>
                        </Col>
                    </Row>
                </Spin>
            </>
        );
    }
}