import React, { Component } from 'react';
import { 
    Row,
    Col,
    Card,
    Input,
    Button,
    Icon,
    Typography,
} from 'antd';
import './index.css';

import { VITSPOT_TIMETABLE_TUTORIAL } from '../../config';
import { OrganisationsLogos } from '../../components';

const Search = Input.Search;
const { Title } = Typography;

export class Home extends Component {
    
    render() {
        return (
            <div>
                <Row gutter={16}>
                    <Col span={8} offset={8}>
                        <div style={{ margin: '24px 0' }} />
                        <Card>
                            <Title level={3}>Instructions</Title>
                            <a href={VITSPOT_TIMETABLE_TUTORIAL} target='_blank' rel="noopener noreferrer"><b>Tutorial for creating and merging timetables.</b></a>
                        </Card>
                        <div style={{ margin: '24px 0' }} />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8} offset={4}>
                        <Card title="Timetable">
                            <Search
                                placeholder="Timetable ID"
                                enterButton="Go"
                                size="large"
                                onSearch={timetableId => window.location = `/timetable/${timetableId}`}
                            />
                            <div style={{ margin: '24px 0' }} />
                            <a href='/timetable/new'><Button type="primary" block><Icon type='plus' /> Add New Timetable</Button></a>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Merge Timetable">
                            <Search
                                placeholder="Merge Timetable ID"
                                enterButton="Go"
                                size="large"
                                onSearch={mergeTimetableId => window.location = `/merge-timetable/${mergeTimetableId}`}
                            />
                            <div style={{ margin: '24px 0' }} />
                            <a href='/merge-timetable/new'><Button type="primary" block><Icon type='plus-circle' /> Add New Merge Timetable</Button></a>
                        </Card>
                    </Col>
                </Row>
                <div style={{ margin: '50px 0' }} />
                <Title level={3} style={{ textAlign: 'center' }}>Partnered Organisations</Title>
                <OrganisationsLogos />
                {/* <Button id="demo" type="primary" shape="round" icon="table" size='large'>Demo</Button> */}
            </div>
        );
    }
}
