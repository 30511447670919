const organisationLogosRaw = [
    {
        name: 'VITspot',
        fileName: 'VITspot-logo.png',
    },
    {
        name: 'VinnovateIT',
        fileName: 'VinnovateIT-logo.png',
    },
    {
        name: 'Becoming I Foundation',
        fileName: 'BIF-logo.jpeg',
    },
    {
        name: 'Sahiti',
        fileName: 'Sahiti-logo.png',
    },
    {
        name: 'Hearts',
        fileName: 'Hearts-logo.png',
    },
    {
        name: 'IEEE SPS',
        fileName: 'IEEE-SPS-Logo.png',
    },
    {
        name: 'Rotaract',
        fileName: 'Rotaract-logo.png',
    },
    {
        name: 'IEEE SSIT',
        fileName: 'IEEE-SSIT-Logo.png',
    },
    {
        name: 'The Photography Club VIT',
        fileName: 'The-Photography-Club-logo.png',
    },
    {
        name: 'ISA Chapter',
        fileName: 'ISA-logo.png',
    },
    {
        name: 'Gravitas',
        fileName: 'graVITas-logo.png',
    },
    {
        name: 'Helphen',
        fileName: 'helphen-logo.png',
    },
    {
        name: 'ISTE',
        fileName: 'ISTE-logo.png',
    },
    {
        name: 'Team Sammard',
        fileName: 'Team-Sammard-logo.jpg',
    },
    {
        name: 'NGO Anokha',
        fileName: 'Anokha-NGO-logo.jpg',
    },
    {
        name: 'Juvenile Care',
        fileName: 'Juvenile-Care-logo.jpg',
    },
    {
        name: 'SIAM VIT',
        fileName: 'SIAM-logo.png',
    },
    {
        name: 'The Next Chapter',
        fileName: 'The-Next-Chapter-logo.png',
    },
    {
        name: 'Cycling Club',
        fileName: 'VIT-Cycling-club-logo.png',
    },
    {
        name: 'Mozilla Student Developer Club VIT',
        fileName: 'VIT-Mozilla-Club-logo.png',
    },
    {
        name: 'CSI VIT',
        fileName: 'CSI-VIT-logo.jpg',
    },
    {
        name: 'Dream Merchants',
        fileName: 'Dream-Merchants-logo.jpg',
    },
    {
        name: 'ECell VIT',
        fileName: 'Ecell-VIT-logo.png',
    },
    {
        name: 'IET',
        fileName: 'IET-logo.jpg',
    },
    {
        name: 'TAG Club',
        fileName: 'TAG-logo.png',
    },
    {
        name: 'Team AutoZ',
        fileName: 'Team-AutoZ-logo.jpg',
    },
    {
        name: 'Team Rotor',
        fileName: 'Team-Rotor-logo.jpg',
    },
    {
        name: 'Team RoverX',
        fileName: 'Team-RoverX-logo.jpg',
    },
    {
        name: 'TEC VIT',
        fileName: 'TEC-VIT-logo.jpg',
    },
];

// Shuffle Array - https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
const shuffleArray = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // Swaping
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export const getOrganisationLogos = ({ shuffle }) => {
    return shuffle ? shuffleArray(organisationLogosRaw): organisationLogosRaw;
}

