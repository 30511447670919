import React, { Component } from 'react';
import {
    Row,
    Col,
    Collapse,
    List,
    Button,
    Spin,
    Modal,
} from 'antd';
import { Mutation } from '@apollo/react-components';

import { DELETE_TIMETABLE_FROM_MERGE_TIMETABLE } from '../../../../api/queries';

const { Panel } = Collapse;

export class MergeTimetableUserList extends Component {

    state = {
        loading: false,
    }

    showErrorModal = () => {
        Modal.error({
            title: 'Error!',
            content: 'Oops something went wrong :(',
        });
    }

    render() {

        const { timetables: users, mergeTimetableId } = this.props.data;

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col span={14} offset={5}>
                        <Collapse defaultActiveKey={['2']}>
                            <Panel header={`User List (${users.length})`} key="1">
                                <Mutation mutation={DELETE_TIMETABLE_FROM_MERGE_TIMETABLE}>
                                    {(deleteTimetableFromMergeTimetable, { data }) => (
                                        <List
                                            bordered
                                            dataSource={users}
                                            renderItem={({ name, registrationNumber, id }) => (
                                                <List.Item>
                                                    <Button type="danger" shape="circle" icon="delete" onClick={async () => {
                                                            try {
                                                                await this.setState({ loading: true });
                                                                await deleteTimetableFromMergeTimetable({ variables: {
                                                                    mergeTimetableId: mergeTimetableId,
                                                                    timetableId: id,
                                                                } });
                                                                await this.setState({ loading: false });
                                                                window.location.reload();
                                                            } catch (error) {
                                                                this.showErrorModal()
                                                            }
                                                    }} />
                                                    <a href={`/timetable/${id}`} target='_blank' rel="noopener noreferrer" style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '10px' }}>
                                                        { `${name} (${registrationNumber})` }
                                                    </a>
                                                </List.Item>
                                            )}
                                        />
                                    )}
                                </Mutation>
                            </Panel>
                        </Collapse>
                        </Col>
                    </Row>
                </Spin>
            </>
        );
    }
}