/* eslint-disable no-throw-literal */
import React, { Component } from 'react';
import { Row, Col, Card, Input, Badge, Button, Modal, Spin } from 'antd';
import { Mutation } from '@apollo/react-components';

import { CREATE_TIMETABLE } from '../../../api/queries';
import { colors } from '../../../config';

const { TextArea } = Input;

export class NewTimetable extends Component {

    state = {
        name: '',
        registrationNumber: '',
        timetable: '',
        timeTableArray: [],
        generateIdButtonIsDisable: true,
        loading: false,
    }

    showErrorModal = ({ title, content }) => {
        Modal.error({
            title: title || 'Error!',
            content,
        });
    }

    showInfoModal = ({ title, content }) => {
        Modal.info({
            title: title || 'Information!',
            content,
        });
    }

    extractTimetable = () => {
    
        try {
            this.setState({generateIdButtonIsDisable: true});

            const rawTimetableText = this.state.timetable;

            let cleanedRawTimetableText = rawTimetableText
                .replace(/Theory\t/g, '')
                .replace(/Lab\t/g, '')
                .replace(/Start\t/g, '')
                .replace(/End\t/g, '')
                .replace(/MON\t/g, '')
                .replace(/TUE\t/g, '')
                .replace(/WED\t/g, '')
                .replace(/THU\t/g, '')
                .replace(/FRI\t/g, '')
                .replace(/SAT\t/g, '')
                .replace(/SUN\t/g, '')
                .trim();
            
            let lines = cleanedRawTimetableText.split("\n");
            if(lines.length !== 14){
                throw "Parsing length Error";
            }

            let timeTableArray = [];

            for (let i = 4; i < lines.length - 1; i = i + 2) {
                let currentDayFirstLine = lines[i].split("\t");
                let currentDaySecondLine = lines[i + 1].split("\t");

                // console.log(currentDayFirstLine.length,currentDaySecondLine.length)
                if(currentDayFirstLine.length !== 15 || currentDaySecondLine.length !== 15){
                    throw "Parsing Error";
                }

                let dayArray = [];
                for (let j = 0; j < currentDayFirstLine.length; j++) {
                    let tableCellTextOne = currentDayFirstLine[j].length >= 10 ? currentDayFirstLine[j] : "";
                    let tableCellTextTwo = currentDaySecondLine[j].length >= 10 ? currentDaySecondLine[j] : "";
                    let tableCellText = tableCellTextOne || tableCellTextTwo;
                    dayArray.push(tableCellText);
                }
                timeTableArray.push(dayArray);
            }
            this.setState({generateIdButtonIsDisable: false});
            this.setState({timeTableArray});
        } catch (e) {
            console.log(e);
            console.log(this.state.timetable);
            this.setState({timeTableArray: []});
            this.showErrorModal({ content: 'There was an error while parsing your text. Please paste again :)' });
            console.log(e);
        }
    }

    renderHTMLTable = () => (
            <table>
                <tbody>
                {
                    this.state.timeTableArray.map((day) => {
                        return (
                            <tr>
                            {
                                day.map((period) => (
                                    <td bgcolor={period.length ? colors.timetableCellFull: colors.timetableCellEmpty} style={{border: `0.5px solid ${colors.timetableCellBorder}`, padding: 5, width: 100}}>{period || 'Free'}</td>
                                ))
                            }
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        )
    
    render() {
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <p>Paste your Timetable here <Badge count="Please use Chrome Browser" /> </p>
                    <TextArea onChange={(e) => this.setState({timetable: e.target.value})} autosize={{ minRows: 20, maxRows: 25 }} />
                    <div style={{ margin: '24px 0' }} />
                    <Card>
                        <Row gutter={16}>
                            <Col span={12}>
                                <p>Name</p>
                                <Input placeholder="Mounish Sai" onChange={(e) => this.setState({name: e.target.value})} />
                                {/* <div style={{ margin: '24px 0' }} />
                                <Input placeholder="Enter Captcha" /> */}
                            </Col>
                            <Col span={12}>
                                <p>Registration Number</p>
                                <Input placeholder="17BIT0000" onChange={(e) => this.setState({registrationNumber: e.target.value})} />
                            </Col>
                        </Row>
                        <div style={{ margin: '24px 0' }} />
                        <Row gutter={16}>
                            <Col span={12}>
                                <Button onClick={() => this.extractTimetable()} block>Extract</Button>
                            </Col>
                            <Col span={12}>
                                <Mutation mutation={CREATE_TIMETABLE}>
                                    {(createTimetable, { data }) => (
                                        <Button type="primary" block disabled={this.state.generateIdButtonIsDisable} onClick={async () => {
                                            if(this.state.name && this.state.registrationNumber){
                                                try {
                                                    this.setState({ loading: true });
                                                    console.log(this.state)
                                                    
                                                    const response = await createTimetable({ variables: {
                                                        name: this.state.name,
                                                        registrationNumber: this.state.registrationNumber,
                                                        timetable: this.state.timeTableArray,
                                                    } });

                                                    this.setState({ loading: false });
                                                    window.location = `/timetable/${response.data.createTimetable.id}`;
                                                } catch (error) {
                                                    console.log(error)
                                                    this.setState({ loading: false });
                                                    this.showErrorModal({});
                                                }
                                            }
                                            else {
                                                this.showInfoModal({ content: 'All fields are mandatory!' });
                                            }
                                        }}>Generate</Button>
                                    )}
                                </Mutation>
                            </Col>
                        </Row>
                    </Card>
                    <div style={{ margin: '24px 0' }} />
                    {this.renderHTMLTable()}
                </Spin>
            </div>
        );
    }
}
