import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReactGA from 'react-ga';

import { LayoutWrapper } from '../components/layout';
import * as Pages from './index';
import { routes, GOOGLE_RECAPTCHA_V3_SITE_KEY, GOOGLE_ANALYTICS_TRACKING_ID } from '../config';
import { GraphQLClient } from '../api';
import "antd/dist/antd.css";

export class Router extends Component {

    renderRoutes = () => (
        routes.map((route) => {
            return(
                <Route exact={true} path={route.path} component={route.page} key={route.path}/>
            )
        })
    );

    componentDidMount() {
        ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
    }
    
    render() {
        return (
            <GoogleReCaptchaProvider
                reCaptchaKey={GOOGLE_RECAPTCHA_V3_SITE_KEY}
                language="en"
            >
                <GraphQLClient>
                    <LayoutWrapper>
                        <BrowserRouter>
                            <Switch>
                                {this.renderRoutes()}
                                <Route path='*' component={() => <Pages.ErrorPage message="404 Not Found!"/>} />
                            </Switch>
                        </BrowserRouter>
                    </LayoutWrapper>
                </GraphQLClient>
            </GoogleReCaptchaProvider>
        );
    }
}
